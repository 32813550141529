import React from "react";
import { Helmet } from "react-helmet";
import ContactComponent from "../components/Contact";
import Footer from "../components/Footer";
import MenuBar from "../components/MenuBar";
import PageContent from "../components/PageContent";

const ContactPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="BAM Elevate Contact Page" />
        <title>Contact Us</title>
      </Helmet>
      <main className="w-screen bg-off-white">
        <MenuBar />
        <PageContent className="md:pt-32">
          <ContactComponent/>
        </PageContent>

        <PageContent>
          <Footer />
        </PageContent>

      </main>
    </>
  )
}

export default ContactPage;